const routes = [
  //小和云校
  {
    path: "/futureEdu/cloudSchool",
    name: "cloudSchool",
    meta: {
      title: "小和云校",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/index"], resolve),
  },
  //课程列表
  {
    path: "/futureEdu/courseList",
    name: "courseList",
    meta: {
      title: "小和云校课程列表",
      keepAlive: false,
      isUseCache: false,
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseList"], resolve),
  },
  //课程详情
  {
    path: "/futureEdu/courseDetail",
    name: "courseDetail",
    meta: {
      title: "小和云校课程详情",
    },
    component: (resolve) =>
      require(["@/views/futureEdu/cloudSchool/courseDetail"], resolve),
  },
];

export default routes;
