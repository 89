const routes = [
  //  垃圾分类
  {
    path: "/rubbishSort/index",
    name: "rubbishSort",
    meta: {
      title: "垃圾分类",
    },
    component: (resolve) => require(["@/views/rubbishSort/index"], resolve),
  },
  //  垃圾分类
  {
    path: "/rubbishSort/home",
    name: "rubbishSort-home",
    meta: {
      title: "垃圾分类",
    },
    component: (resolve) => require(["@/views/rubbishSort/home"], resolve),
  },
  //  垃圾分类学习视频
  {
    path: "/rubbishSort/studyVideo",
    name: "studyVideo",
    meta: {
      title: "垃圾分类学习视频",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/studyVideo"], resolve),
  },
  //
  {
    path: "/rubbishSort/rubbishSortCamera",
    name: "rubbishSortCamera",
    meta: {
      title: "垃圾拍照",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/rubbishSortCamera"], resolve),
  },
  {
    path: "/rubbishSort/rubbishSortList",
    name: "rubbishSortList",
    meta: {
      title: "垃圾拍照记录",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/rubbishSortList"], resolve),
  },
  {
    path: "/rubbishSort/answerRecord",
    name: "answerRecord",
    meta: {
      title: "答题记录",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/answerRecord"], resolve),
  },
  {
    path: "/rubbishSort/answerRecordTwo",
    name: "answerRecordTwo",
    meta: {
      title: "答题记录",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/answerRecordTwo"], resolve),
  },
  {
    path: "/rubbishSort/recordDetail",
    name: "recordDetail",
    meta: {
      title: "答题详情",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/recordDetail"], resolve),
  },
  {
    path: "/rubbishSort/question",
    name: "question",
    meta: {
      title: "答题",
    },
    component: (resolve) => require(["@/views/rubbishSort/question"], resolve),
  },
  {
    path: "/rubbishSort/promiseLetter",
    name: "promiseLetter",
    meta: {
      title: "承诺书",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/promiseLetter"], resolve),
  },
  {
    path: "/rubbishSort/myLetter",
    name: "myLetter",
    meta: {
      title: "承诺书",
    },
    component: (resolve) => require(["@/views/rubbishSort/myLetter"], resolve),
  },
  {
    path: "/rubbishSort/reviewCommitment",
    name: "reviewCommitment",
    meta: {
      title: "承诺书审核",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/reviewCommitment"], resolve),
  },
  {
    path: "/rubbishSort/photoReview",
    name: "photoReview",
    meta: {
      title: "照片审核",
    },
    component: (resolve) =>
      require(["@/views/rubbishSort/photoReview"], resolve),
  },
  {
    path: "/rubbishSort/myReview",
    name: "myReview",
    meta: {
      title: "我的审核",
    },
    component: (resolve) => require(["@/views/rubbishSort/myReview"], resolve),
  },
  {
    path: "/rubbishSort/myReview/more",
    name: "more",
    meta: {
      title: "我的审核-更多",
    },
    component: (resolve) => require(["@/views/rubbishSort/more"], resolve),
  },
];

export default routes;
