const routes = [
  // 商圈列表
  {
    path: "/futureService/businessDistrictList",
    name: "businessDistrictList",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessDistrictList.vue",
      ], resolve),
  }, // 修改商户地址
  {
    path: "/futureService/merchantAddressEdit",
    name: "merchantAddressEdit",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/merchantAddressEdit.vue",
      ], resolve),
  }, // 商圈详情
  {
    path: "/futureService/businessDistrictDetail",
    name: "businessDistrictDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessDistrictDetail.vue",
      ], resolve),
  }, //商户信息
  {
    path: "/futureService/businessCircle",
    name: "businessCircle",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/businessCircle/index.vue"], resolve),
  }, //商圈冻结申诉
  {
    path: "/futureService/appeal",
    name: "appeal",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureService/businessCircle/appeal.vue"], resolve),
  }, //商圈申请
  {
    path: "/futureService/businessCircleApply",
    name: "businessCircleApply",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessCircleApply.vue",
      ], resolve),
  }, //商品订单
  {
    path: "/futureService/businessGood",
    name: "businessGood",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessGood.vue",
      ], resolve),
  }, //订单详情
  {
    path: "/futureService/businessGoodDetail",
    name: "businessGoodDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/businessCircle/businessGoodDetail.vue",
      ], resolve),
  },
  // 积分获取规则
  {
    path: "/futureService/pointsRule",
    name: "pointsRule",
    meta: {
      title: "积分获取规则",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointsRule.vue"], resolve),
  },
  // 积分页面
  {
    path: "/futureService/points",
    name: "points",
    meta: {
      title: "积分",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/index"], resolve),
  },
  //积分明细
  {
    path: "/futureService/pointsList",
    name: "pointsList",
    meta: {
      title: "积分明细",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointsList"], resolve),
  },
  //积分排行
  {
    path: "/futureService/pointRankList",
    name: "pointRankList",
    meta: {
      title: "积分排行",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointRankList"], resolve),
  },
  //积分转赠
  {
    path: "/futureService/pointIncrease",
    name: "pointIncrease",
    meta: {
      title: "积分转赠",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/pointIncrease"], resolve),
  },
  //转赠记录
  {
    path: "/futureService/transferRecord",
    name: "transferRecord",
    meta: {
      title: "转赠记录",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/transferRecord"], resolve),
  },
  //获取积分
  {
    path: "/futureService/getPoints",
    name: "getPoints",
    meta: {
      title: "获取积分",
    },
    component: (resolve) =>
      require(["@/views/futureService/points/getPoints"], resolve),
  },

  //签到
  {
    path: "/futureService/daySign",
    name: "daySign",
    meta: {
      title: "每日签到",
    },
    component: (resolve) =>
      require(["@/views/futureService/daySign/index"], resolve),
  },

  // 商城
  {
    path: "/futureService/mall",
    name: "mall",
    meta: {
      title: "商城",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/index"], resolve),
  },
  //商城详情
  {
    path: "/futureService/mallDetail",
    name: "mallDetail",
    meta: {
      title: "商城详情",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallDetail"], resolve),
  },
  //兑换成功
  {
    path: "/futureService/mallFinished",
    name: "mallFinished",
    meta: {
      title: "兑换成功",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallFinished"], resolve),
  },
  //订单详情
  {
    path: "/futureService/mallOrderDetail",
    name: "mallOrderDetail",
    meta: {
      title: "订单详情",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallOrderDetail"], resolve),
  },
  //订单列表
  {
    path: "/futureService/mallOrderList",
    name: "mallOrderList",
    meta: {
      title: "订单列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/mall/mallOrderList"], resolve),
  },
  // 场地预约
  {
    path: "/futureService/areaAppoint",
    name: "areaAppoint",
    meta: {
      title: "场地预约",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/index"], resolve),
  },
  // 场地详情
  {
    path: "/futureService/areaDetail",
    name: "areaDetail",
    meta: {
      title: "场地详情",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/areaDetail"], resolve),
  },
  // 预约内容
  {
    path: "/futureService/apointmentContent",
    name: "apointmentContent",
    meta: {
      title: "预约内容",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/apointmentContent"], resolve),
  },
  // 场次预约
  {
    path: "/futureService/areaSession",
    name: "areaSession",
    meta: {
      title: "选择场次",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/areaSession"], resolve),
  },
  // 预约成功
  {
    path: "/futureService/appointSuccess",
    name: "appointSuccess",
    meta: {
      title: "预约成功",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/appointSuccess"], resolve),
  },
  // 我的预约
  {
    path: "/futureService/appointList",
    name: "appointList",
    meta: {
      title: "我的预约",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/appointList"], resolve),
  },
  // 预约订单详情
  {
    path: "/futureService/appointDetail",
    name: "appointDetail",
    meta: {
      title: "我的预约订单详情",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/appointDetail"], resolve),
  },
  // 查看团队积分
  {
    path: "/futureService/teamPoints",
    name: "teamPoints",
    meta: {
      title: "查看团队积分",
    },
    component: (resolve) =>
      require(["@/views/futureService/areaAppoint/teamPoints"], resolve),
  },
  // 小和广播站列表
  {
    path: "/futureService/broadcastList",
    name: "broadcastList",
    meta: {
      title: "小和广播站列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/heBroadcast/broadcastList"], resolve),
  },
  // 小和广播站
  {
    path: "/futureService/broadcastReplyDetail",
    name: "broadcastReplyDetail",
    meta: {
      title: "小和广播站",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/heBroadcast/broadcastReplyDetail",
      ], resolve),
  },
  //商家订单详情
  {
    path: "/futureService/verificationDetail",
    name: "verificationDetail",
    meta: {
      title: "订单详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/verification/verificationDetail",
      ], resolve),
  },
  //商家订单列表
  {
    path: "/futureService/verificationList",
    name: "verificationList",
    meta: {
      title: "订单列表",
    },
    component: (resolve) =>
      require(["@/views/futureService/verification/verificationList"], resolve),
  },
  //问卷调查列表
  {
    path: "/futureService/questionnaireList",
    name: "questionnaireList",
    meta: {
      title: "问卷调查",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/questionnaire/questionnaireList.vue",
      ], resolve),
  },
  // //问卷详情
  // {
  //   path: "/futureService/questionnaire",
  //   name: "questionnaireAnswer",
  //   meta: {
  //     title: "问卷答题",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureService/questionnaire/questionnaireAnswer.vue",
  //     ], resolve),
  // },
  //和丰码
  {
    path: "/futureService/commonCode",
    name: "commonCode",
    meta: {
      title: "和丰码",
    },
    component: (resolve) =>
      require(["@/views/futureService/commonCode/index.vue"], resolve),
  },
  //申请历史
  {
    path: "/futureService/applicationHistory",
    name: "applicationHistory",
    meta: {
      title: "申请历史",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/commonCode/applicationHistory.vue",
      ], resolve),
  },
  //申请开门二维码
  {
    path: "/futureService/applyForQRcode",
    name: "applyForQRcode",
    meta: {
      title: "申请开门二维码",
    },
    component: (resolve) =>
      require(["@/views/futureService/commonCode/applyForQRcode.vue"], resolve),
  },
  //党建首页
  {
    path: "/futureService/partyBuilding",
    name: "partyBuilding",
    meta: {
      title: "小和党建",
    },
    component: (resolve) =>
      require(["@/views/futureService/partyBuilding/home/index.vue"], resolve),
  },
  {
    path: "/futureService/partyTopic",
    name: "partyTopic",
    meta: {
      title: "小和党建",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/home/partyTopic.vue",
      ], resolve),
  },
  //亮相台
  {
    path: "/futureService/partyMember",
    name: "partyMember",
    meta: {
      title: "亮相台",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyMember/index.vue",
      ], resolve),
  },
  //党务通
  {
    path: "/futureService/partyAffairs",
    name: "partyAffairs",
    meta: {
      title: "党务通",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/index.vue",
      ], resolve),
  },
  //三会一课
  {
    path: "/futureService/partyAffairsList",
    name: "partyAffairsList",
    meta: {
      title: "三会一课",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyAffairsList.vue",
      ], resolve),
  },
  //党公告
  {
    path: "/futureService/partyNotice",
    name: "partyNotice",
    meta: {
      title: "党公告",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyNotice.vue",
      ], resolve),
  },
  //主题党日
  {
    path: "/futureService/topicParty",
    name: "topicParty",
    meta: {
      title: "主题党日",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/topicParty.vue",
      ], resolve),
  },
  //党务通
  {
    path: "/futureService/partyAffairsDetail",
    name: "partyAffairsDetail",
    meta: {
      title: "党务通",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyAffairsDetail.vue",
      ], resolve),
  },
  //党员热议
  {
    path: "/futureService/rightsComment",
    name: "rightsComment",
    meta: {
      title: "党员热议",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/rightsComment.vue",
      ], resolve),
  },
  //课程表
  {
    path: "/futureService/classScheduleCard",
    name: "classScheduleCard",
    meta: {
      title: "课程表",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/classScheduleCard.vue",
      ], resolve),
  },
  //师资详情
  {
    path: "/futureService/teacherDetail",
    name: "teacherDetail",
    meta: {
      title: "师资库",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/teacherDetail.vue",
      ], resolve),
  },
  //师资库
  {
    path: "/futureService/teacherLibrary",
    name: "teacherLibrary",
    meta: {
      title: "师资库",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/teacherLibrary.vue",
      ], resolve),
  },
  //在线学习
  {
    path: "/futureService/onlineStudy",
    name: "onlineStudy",
    meta: {
      title: "在线学习",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/onlineStudy.vue",
      ], resolve),
  },
  {
    path: "/futureService/onLineStudyDetail",
    name: "onLineStudyDetail",
    meta: {
      title: "在线学习详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/onLineStudyDetail.vue",
      ], resolve),
  },
  // 基层党校
  {
    path: "/futureService/grassRootsPartySchool",
    name: "grassRootsPartySchool",
    meta: {
      title: "基层党校",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/grassRootsPartySchool.vue",
      ], resolve),
  },
  //红管家
  {
    path: "/futureService/redHousekeeper",
    name: "redHousekeeper",
    meta: {
      title: "红管家",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/redHousekeeper.vue",
      ], resolve),
  },
  //组织架构
  {
    path: "/futureService/partyOrg",
    name: "partyOrg",
    meta: {
      title: "红管家",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/partyBuilding/partyAffairs/partyOrg.vue",
      ], resolve),
  },
  //小和托幼
  {
    path: "/futureService/entrustChild",
    name: "entrustChild",
    meta: {
      title: "小和托幼",
    },
    component: (resolve) =>
      require(["@/views/futureService/entrustChild/index.vue"], resolve),
  },
  //小和托幼
  {
    path: "/futureService/entrustChildList",
    name: "entrustChildList",
    meta: {
      title: "小和托幼",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/entrustChild/entrustChildList.vue",
      ], resolve),
  },
  //小和托幼审核
  {
    path: "/futureService/entrustManager",
    name: "entrustManager",
    meta: {
      title: "小和托幼审核",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/entrustChild/entrustManager.vue",
      ], resolve),
  },
  //我的预约
  {
    path: "/futureService/myEntrustChild",
    name: "myEntrustChild",
    meta: {
      title: "我的预约",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/entrustChild/myEntrustChild.vue",
      ], resolve),
  },
  //小和托幼视频
  {
    path: "/futureService/entrustChildVideo",
    name: "entrustChildVideo",
    meta: {
      title: "小和托幼视频",
    },
    component: (resolve) =>
      require([
        "@/views/futureService/entrustChild/entrustChildVideo.vue",
      ], resolve),
  },
];

export default routes;
