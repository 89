<template>
  <div class="v-empty">
    <van-empty class="custom-image" :image="image" :description="description" />
  </div>
</template>

<script>
import Vue from "vue";
import { Empty } from "vant";

Vue.use(Empty);
export default {
  watch: {},
  components: {},
  props: {
    description: {
      type: String,
      default: "暂无数据",
    },

    image: {
      type: String,
      default: require("./img/nodata.png"),
    },
    maxlength: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less">
.v-empty {
  .custom-image .van-empty__image {
    width: 90px;
    height: 90px;
  }
}
</style>
