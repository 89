const routes = [
  // 活动管理
  //活动列表
  {
    path: "/futureNeighbourhood/activityList",
    name: "activityList",
    meta: {
      title: "活动列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activityManage/activityList.vue",
      ], resolve),
  },
  //活动参与人列表
  {
    path: "/futureNeighbourhood/activityUserList",
    name: "activityUserList",
    meta: {
      title: "活动参与人列表",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/activityManage/activityUserList.vue",
      ], resolve),
  },
  // 活动报名
  //活动列表
  {
    path: "/futureNeighbourhood/applyActivity",
    name: "applyActivity",
    meta: {
      title: "活动列表",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/index"], resolve),
  },
  //活动详情
  {
    path: "/futureNeighbourhood/activityDetail",
    name: "activityDetail",
    meta: {
      title: "活动详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/activityDetail",
      ], resolve),
  },
  // 发布活动
  {
    path: "/futureNeighbourhood/publishActivity",
    name: "publishActivity",
    meta: {
      title: "发布活动",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/publishActivity",
      ], resolve),
  },
  //报名提交页面
  {
    path: "/futureNeighbourhood/sureSubmit",
    name: "sureSubmit",
    meta: {
      title: "报名提交",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/sureSubmit",
      ], resolve),
  },
  //活动工单详情
  {
    path: "/futureNeighbourhood/orderDetail",
    name: "orderDetail",
    meta: {
      title: "活动工单详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/orderDetail",
      ], resolve),
  },
  //新增联系人
  {
    path: "/futureNeighbourhood/addUser",
    name: "addUser",
    meta: {
      title: "新增联系人",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/addUser"], resolve),
  },
  //我的报名
  {
    path: "/futureNeighbourhood/myActivityList",
    name: "myActivityList",
    meta: {
      title: "我的报名",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/applyActivity/myActivityList",
      ], resolve),
  },
  //我发布的活动
  {
    path: "/futureNeighbourhood/myPublish",
    name: "myPublish",
    meta: {
      title: "我发布的活动",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/applyActivity/myPublish"], resolve),
  },
  // 提交社区共建
  {
    path: "/futureNeighbourhood/commonCreate",
    name: "commonCreate",
    meta: {
      title: "社区共建",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/commonCreate/index"], resolve),
  },
  //我的共建建议
  {
    path: "/futureNeighbourhood/myCreate",
    name: "myCreate",
    meta: {
      title: "我的共建建议",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/myCreate.vue",
      ], resolve),
  },
  //共建建议详情
  {
    path: "/futureNeighbourhood/createDetail",
    name: "createDetail",
    meta: {
      title: "共建建议详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/createDetail.vue",
      ], resolve),
  },
  //社区共建墙
  {
    path: "/futureNeighbourhood/createList",
    name: "createList",
    meta: {
      title: "社区共建",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/commonCreate/createList.vue",
      ], resolve),
  },
  // 公益捐赠
  {
    path: "/futureNeighbourhood/donate",
    name: "donate",
    meta: {
      title: "公益捐赠",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/index"], resolve),
  },
  //我的捐赠
  {
    path: "/futureNeighbourhood/myDonate",
    name: "myDonate",
    meta: {
      title: "我的捐赠",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/myDonate.vue"], resolve),
  },
  //捐赠详情
  {
    path: "/futureNeighbourhood/donateDetail",
    name: "donateDetail",
    meta: {
      title: "捐赠详情",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/donateDetail.vue"], resolve),
  },
  //我的捐赠详情
  {
    path: "/futureNeighbourhood/myDonateDetail",
    name: "myDonateDetail",
    meta: {
      title: "我的捐赠详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/donate/myDonateDetail.vue",
      ], resolve),
  },
  //捐赠墙
  {
    path: "/futureNeighbourhood/donateList",
    name: "donateList",
    meta: {
      title: "公益捐赠",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/donate/donateList.vue"], resolve),
  },
  //社区达人
  {
    path: "/futureNeighbourhood/talent",
    name: "talent",
    meta: {
      title: "和·伙人",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/talent/index"], resolve),
  },
  //申请社区达人
  {
    path: "/futureNeighbourhood/applyTalent",
    name: "applyTalent",
    meta: {
      title: "申请和·伙人",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/talent/applyTalent"], resolve),
  },
  // 和伙人风采列表页
  {
    path: "/futureNeighbourhood/partnerStyleList",
    name: "partnerStyleList",
    meta: {
      title: "和·伙人风采",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/partner/partnerStyleList.vue",
      ], resolve),
  },
  // 和·伙人风采标题
  {
    path: "/futureNeighbourhood/partnerStyleTitle",
    name: "partnerStyleTitle",
    meta: {
      title: "和·伙人风采标题",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/partner/partnerStyleTitle.vue",
      ], resolve),
  },
  // 回复
  {
    path: "/futureNeighbourhood/replyDetail",
    name: "replyDetail",
    meta: {
      title: "回复",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/partner/replyDetail.vue"], resolve),
  },
  // 提交心愿
  {
    path: "/futureNeighbourhood/wishes",
    name: "wishes",
    meta: {
      title: "未来心愿",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/index"], resolve),
  },
  //我的心愿
  {
    path: "/futureNeighbourhood/myWishes",
    name: "myWishes",
    meta: {
      title: "我的心愿",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/myWishes.vue"], resolve),
  },
  //心愿详情
  {
    path: "/futureNeighbourhood/wishDetail",
    name: "wishDetail",
    meta: {
      title: "心愿详情",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/wishDetail.vue"], resolve),
  },
  //心愿墙列表
  {
    path: "/futureNeighbourhood/wishList",
    name: "wishList",
    meta: {
      title: "未来心愿",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/wishes/wishList.vue"], resolve),
  },
  //话题列表
  {
    path: "/futureNeighbourhood/topicList",
    name: "topicList",
    meta: {
      title: "话题列表",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicList.vue"], resolve),
  },
  //话题详情
  {
    path: "/futureNeighbourhood/topicTitle",
    name: "topicTitle",
    meta: {
      title: "话题详情",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/topic/topicTitle.vue"], resolve),
  },
  //话题回复
  {
    path: "/futureNeighbourhood/topicReplyDetail",
    name: "topicReplyDetail",
    meta: {
      title: "回复详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/topic/topicReplyDetail.vue",
      ], resolve),
  },
  //小和互动
  {
    path: "/futureNeighbourhood/interaction",
    name: "interaction",
    meta: {
      title: "小和互动",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/interaction/index.vue"], resolve),
  },
  //社群
  {
    path: "/futureNeighbourhood/association",
    name: "association",
    meta: {
      title: "小和社群",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/association/index.vue"], resolve),
  },
  //社群成员
  {
    path: "/futureNeighbourhood/communityMembers",
    name: "communityMembers",
    meta: {
      title: "社群成员",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/communityMembers/index.vue",
      ], resolve),
  },
  //社群详情
  {
    path: "/futureNeighbourhood/associationDetail",
    name: "associationDetail",
    meta: {
      title: "小和社群详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/association/associationDetail.vue",
      ], resolve),
  },
  //心愿审核列表
  {
    path: "/futureNeighbourhood/verifyWish",
    name: "verifyWish",
    meta: {
      title: "心愿审核列表",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/verifyWish/index.vue"], resolve),
  },
  //心愿审核详情
  {
    path: "/futureNeighbourhood/verifyWishDetail",
    name: "verifyWishDetail",
    meta: {
      title: "心愿审核详情",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/verifyWish/verifyWishDetail.vue",
      ], resolve),
  },
  //小和管家
  {
    path: "/futureNeighbourhood/xiaoheHouseKeeper",
    name: "xiaoheHouseKeeper",
    meta: {
      title: "小和管家",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/xiaoheHouseKeeper/index.vue",
      ], resolve),
  },
  //点餐
  {
    path: "/futureNeighbourhood/orderMeal",
    name: "orderMeal",
    meta: {
      title: "点餐",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/orderMeal/index.vue"], resolve),
  },
  //点餐确认订单
  {
    path: "/futureNeighbourhood/toOrderMeal",
    name: "toOrderMeal",
    meta: {
      title: "点餐确认订单",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/toOrderMeal.vue",
      ], resolve),
  },
  //点餐成功
  {
    path: "/futureNeighbourhood/orderMealSuccess",
    name: "orderMealSuccess",
    meta: {
      title: "点餐成功",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/orderMealSuccess.vue",
      ], resolve),
  },
  // //点餐成功
  // {
  //   path: "/futureNeighbourhood/orderMealSuccess",
  //   name: "orderMealSuccess",
  //   meta: {
  //     title: "点餐成功",
  //   },
  //   component: (resolve) =>
  //     require([
  //       "@/views/futureNeighbourhood/orderMeal/orderMealSuccess.vue",
  //     ], resolve),
  // },
  //商家管理订单
  {
    path: "/futureNeighbourhood/bussinessMeal",
    name: "bussinessMeal",
    meta: {
      title: "商家管理订单",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/bussinessMeal.vue",
      ], resolve),
  },
  //点餐订单
  {
    path: "/futureNeighbourhood/orderMealList",
    name: "orderMealList",
    meta: {
      title: "点餐订单",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/orderMeal/orderMealList.vue",
      ], resolve),
  },
  // 法律咨询
  {
    path: "/futureNeighbourhood/legalAdvice",
    name: "legalAdvice",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require(["@/views/futureNeighbourhood/legalAdvice/index.vue"], resolve),
  },
  // 法律服务指引
  {
    path: "/futureNeighbourhood/lawServiceGuide",
    name: "lawServiceGuide",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/legalAdvice/lawServiceGuide.vue",
      ], resolve),
  },
  // 法律服务指引
  {
    path: "/futureNeighbourhood/lawServiceGuideDetail",
    name: "lawServiceGuideDetail",
    meta: {
      title: "",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/legalAdvice/lawServiceGuideDetail.vue",
      ], resolve),
  },
  // 法律咨询
  {
    path: "/futureNeighbourhood/legalAdviceHome",
    name: "legalAdviceHome",
    meta: {
      title: "小和法治",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/legalAdvice/legalAdviceHome.vue",
      ], resolve),
  },

  /** 邻里公约 */
  // 邻里公约 - 首页
  {
    path: "/futureNeighbourhood/neighbourhoodTreaty",
    name: "neighbourhoodTreaty",
    meta: {
      title: "邻里公约",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/neighbourhoodTreaty/index.vue",
      ], resolve),
  },
  // 邻里公约 - 签署页
  {
    path: "/futureNeighbourhood/signTreaty",
    name: "signTreaty",
    meta: {
      title: "签署公约",
    },
    component: (resolve) =>
      require([
        "@/views/futureNeighbourhood/neighbourhoodTreaty/signTreaty.vue",
      ], resolve),
  },
  /** end */
];

export default routes;
