<template>
  <div id="app">
    <keep-alive>
      <!-- 需要缓存的视图组件 -->
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- 不需要缓存的视图组件 -->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import { getHashParam } from "@/utils/utils.js";

export default {
  name: "areaAppoint",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less">
#app {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}
@import url("./assets/less/reset.less");
@import url("./assets/less/common.less");
</style>
