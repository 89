<template>
  <van-checkbox-group :value="value" @input="onInput">
    <van-cell-group v-if="isHasCellGroup">
      <van-cell
        v-for="(item, index) in list"
        clickable
        :key="`${index}_${item.value}`"
        :title="item.text"
        @click="toggle(index)"
      >
        <template #right-icon>
          <van-checkbox :name="item.value" ref="checkboxes" />
        </template>
      </van-cell>
    </van-cell-group>
    <template v-else>
      <van-checkbox
        v-for="(item, index) in list"
        :key="`${index}_${item.value}`"
        :name="item"
      >
        {{ item.text }}
      </van-checkbox>
    </template>
  </van-checkbox-group>
</template>

<script>
import { Checkbox, CheckboxGroup, Cell, CellGroup } from "vant";

export default {
  model: {
    prop: "value",
    event: "input",
  },
  components: {
    "van-checkbox": Checkbox,
    "van-checkbox-group": CheckboxGroup,
    "van-cell": Cell,
    "van-cell-group": CellGroup,
  },
  props: {
    // v-model 绑定的值
    value: {
      type: [String, Array],
      required: true,
    },
    // 是否搭配单元格组件使用
    isHasCellGroup: {
      type: Boolean,
      default: true,
    },
    // 多选控件选项数据 [{ text: '', value: '' }]
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /* 该组件未适配vant所有属性，可自行添加 van-checkbox-group，van-checkbox 组件属性  */
  },
  methods: {
    onInput(val) {
      this.$emit("input", val);
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
  },
};
</script>

<style></style>
