// const protocol = "http";
const protocol = "https"; // 测试
// const host = "future.zhuneng.cn";
const host = "dev.zhuneng.cn"; // 测试
// const host = "hfzwytest.zhuneng.cn"; // 测试
// const host = "39.103.230.251"; // 生产
const origin = protocol + "://" + host;

module.exports = {
  origin,
};
