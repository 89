<template>
  <div class="v-dropDownMenu">
    <van-dropdown-menu :active-color="activeColor">
      <van-dropdown-item v-model="model" :options="option1" @change="change" />
    </van-dropdown-menu>
  </div>
</template>

<script>
import Vue from "vue";
import { DropdownMenu, DropdownItem } from "vant";

Vue.use(DropdownMenu);
Vue.use(DropdownItem);

export default {
  components: {},
  props: {
    finished: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    activeColor: {
      type: String,
      default: "#007eff",
    },
    autoplay: {
      type: Number,
      default: 3000,
    },
    option1: {
      type: Array,
      default: function () {
        return [{}];
      },
    },
  },
  watch: {
    model(newValue) {
      console.log(newValue, 23);
      this.$emit("input", newValue, this);
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less">
.v-search {
  width: 100%;
}
</style>
