<template>
  <div>
    <van-popup
      v-model="show"
      @click-overlay="clickOverlay"
      :position="position"
    >
      <van-datetime-picker
        v-model="currentDate"
        :type="type"
        @cancel="cancel"
        @confirm="confirmPicker"
        :title="title"
        :min-date="minDate"
        :max-date="maxDate"
        :max-hour="maxHour"
        :min-hour="minHour"
        :max-minute="maxMinute"
        :min-minute="minMinute"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { DatetimePicker, Popup } from "vant";
Vue.use(DatetimePicker);
Vue.use(Popup);
export default {
  components: {},
  props: {
    // 是否自动关闭控件
    title: {
      type: String,
      default: "选择年月日",
    },
    isAuto: {
      type: Boolean,
      default: false,
    },
    // 日期显示规则，暂时未用到
    formatter: {
      type: Function,
      default: function () {
        return;
      },
    },
    maxHour: [Number, String],
    minHour: [Number, String],
    maxMinute: [Number, String],
    minMinute: [Number, String],
    // 弹出窗定位的位置
    position: {
      type: String,
      default: "bottom",
    },
    // 最大可选日期
    maxDate: { type: Date },
    // 最小可选日期
    minDate: {
      type: Date,
      default: function () {
        return new Date(1700, 0, 1);
      },
    },
    // 用于控件是否显示的变量
    valueShow: {
      type: Boolean,
      default: false,
    },
    // 功能未知
    radioObj: {
      type: Array,
      default: function () {
        return [{ name: "", value: "" }];
      },
    },
    // 日期控件的类型
    type: {
      type: String,
      default: "date",
    },
    // 默认选中的日期
    value: [Date, String],
  },
  watch: {
    valueShow(newValue, oldValue) {
      this.show = newValue;
    },
  },
  data() {
    return {
      show: this.valueShow,
      currentDate: this.value,
    };
  },
  methods: {
    // 点击时间选择器完成时触发
    confirmPicker() {
      this.$emit("confirmPicker", this.currentDate);
      if (this.isAuto) {
        this.$emit("update:valueShow", false);
      }
    },
    // 点击遮罩层触发
    clickOverlay() {
      this.$emit("clickOverlay");
      if (this.isAuto) {
        this.$emit("update:valueShow", false);
      }
    },
    // 点击时间选择器取消时触发
    cancel() {
      this.$emit("cancel");
      if (this.isAuto) {
        this.$emit("update:valueShow", false);
      }
    },
    // 暂时未用到,后续封装
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
