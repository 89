<template>
  <div>
    <van-field
      v-model="model"
      :type="type"
      :size="size"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :show-word-limit="showWordLimit"
      :autosize="autosize"
      :disabled="disabled"
      :input-align="inputAlign"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Field } from "vant";
Vue.use(Field);
export default {
  watch: {
    value(newValue, oldValue) {
      this.changeModel(newValue);
    },
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  components: {},
  props: {
    size: {
      type: String,
      default: "mini",
    },
    autosize: {
      type: Object,
      default: function () {
        return { maxHeight: 80, minHeight: 80 };
      },
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: 300,
    },
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    inputAlign: {
      type: String,
      default: "left",
    },
    columns: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    changeModel(newValue) {
      this.model = newValue;
      // 输入框的值改变时触发
      this.$emit("change", newValue);
    },
    onBlur() {
      this.$emit("blur");
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style lang="less"></style>
