<template>
  <div class="commonOpera">
    <div class="commonIcons">
      <div class="jump_list-page" @click="tomy" v-if="model.showMy">
        <!-- <img src="@/assets/img/add.png" alt="" /> -->
        <div class="btn_photo">
          <img
            :src="require(`@/assets/img/${commonOperaParams.imgUrl}.png`)"
            alt=""
          />
        </div>
        <div class="btn_text">
          <span>{{ commonOperaParams.listText }}</span>
        </div>
      </div>
      <div
        class="jump_create-page"
        @click="add"
        v-if="model.showOpera && model.showMy"
      >
        <!-- <img src="@/assets/img/my.png" alt="" /> -->
        <span>{{ commonOperaParams.createText }}</span>
      </div>
      <div
        class="jump_create-page--single"
        @click="add"
        v-if="model.showOpera && !model.showMy"
      >
        <!-- <img src="@/assets/img/my.png" alt="" /> -->
        <span>{{ commonOperaParams.createText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRegister } from "@/utils/common";
export default {
  name: "commonOpera",
  props: {
    commonOperaParams: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      model: {},
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  watch: {
    commonOperaParams: {
      handler(newName, oldName) {
        let obj = {
          createText: "",
          listText: "",
          imgUrl: "",
          showMy: "true", //是否展示跳转我的列表
          showOpera: "true", //是否展示操作按钮
          isCommonOpera: false, //是否展示公共操作组件
          name: "", //判断是否需要房号的 key
          operaUrl: "", // 跳转操作页面的路由name值
          myUrl: "", // 跳转我的页面的路由name值
          noMyOpera: false, //判断执行回调以后是否跳转，true不跳转，false 跳转，默认跳转
        };
        for (let i in obj) {
          if (Object.keys(newName).indexOf(i) != -1) {
            obj[i] = newName[i];
          }
        }
        this.model = obj;
      },
      // 代表在wacth里声明了firstName这个方法之后立即先去执行handler方法
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async add() {
      console.log(this.$route.path, this.$route.query, this.model);
      if (
        await toRegister(this.$route.path, this.$route.query, this.model.name)
      ) {
        console.log(7876, this.model.noMyOpera);
        if (this.model.noMyOpera) {
          this.$emit("beforeMy");
          return;
        } else {
          this.$emit("beforeMy");
        }
        this.$router.push({
          name: this.model.operaUrl,
        });
      }
    },
    async tomy() {
      if (
        await toRegister(this.$route.path, this.$route.query, this.model.name)
      ) {
        this.$router.push({
          name: this.model.myUrl,
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.commonOpera {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 96px;
  background: #ffffff;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .commonIcons {
    display: flex;
    width: 100%;
    .jump_list-page {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      padding: 10px 36px;
      .btn_photo {
        width: 48px;
        height: 48px;
        // overflow: hidden;
        img {
          vertical-align: middle;
          width: 44px;
          height: 44px;
          margin-bottom: 4px;
        }
      }
      .btn_text {
        display: flex;
        font-size: 24px;
        font-weight: bold;
        color: #323334;
        line-height: 28px;
        height: 28px;
      }
    }
    .jump_create-page {
      flex: 1;
      background: #007eff;
      border-radius: 38px;
      margin: 10px 32px 10px 0;
      text-align: center;
      span {
        vertical-align: middle;
        line-height: 76px;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
      }
    }
    .jump_create-page--single {
      flex: 1;
      background: #007eff;
      // border-radius: 38px;
      // margin: 10px 32px 10px 0;
      text-align: center;
      span {
        vertical-align: middle;
        line-height: 96px;
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}
</style>
