const routes = [
  //  临时公告
  {
    path: "/home/tempNotice",
    name: "tempNotice",
    meta: {
      title: "临时公告",
    },
    component: (resolve) => require(["@/views/other/tempNotice"], resolve),
  },
  //  联系小和
  {
    path: "/home/notice",
    name: "notice",
    meta: {
      title: "联系小和",
    },
    component: (resolve) => require(["@/views/other/notice"], resolve),
  },
  //  小和广播站
  {
    path: "/home/introduce",
    name: "introduce",
    meta: {
      title: "未来社区介绍",
    },
    component: (resolve) => require(["@/views/other/introduce"], resolve),
  },
  {
    path: "/home/manual",
    name: "manual",
    meta: {
      title: "小程序用户使用手册",
    },
    component: (resolve) => require(["@/views/other/manual"], resolve),
  },
  {
    path: "/home/treaty",
    name: "treaty",
    meta: {
      title: "邻里公约",
    },
    component: (resolve) => require(["@/views/other/treaty"], resolve),
  },
  //我的意见
  {
    path: "/home/myAdvice",
    name: "myAdvice",
    meta: {
      title: "我的意见",
    },
    component: (resolve) => require(["@/views/other/myAdvice"], resolve),
  }, //意见反馈
  {
    path: "/home/myAdviceForm",
    name: "myAdviceForm",
    meta: {
      title: "意见反馈",
    },
    component: (resolve) => require(["@/views/other/myAdviceForm"], resolve),
  },
];

export default routes;
