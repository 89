import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
let viewsRoutes = [];
const routerContext = require.context("./module", false, /\.js$/);
routerContext.keys().forEach((route) => {
  const routerModule = routerContext(route);
  viewsRoutes = [...viewsRoutes, ...(routerModule.default || routerModule)];
});
console.log(viewsRoutes, 22);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  ...viewsRoutes,
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = to.query.titleName || "";
  }
  next();
});
export default router;
