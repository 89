<template>
  <van-pull-refresh :value="value" @input="onInput" @refresh="onRefresh">
    <slot></slot>
  </van-pull-refresh>
</template>

<script>
export default {
  name: "v-refresh",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    // v-model 绑定值
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onRefresh(val) {
      this.$emit("refresh", val);
    },
    onInput(val) {
      this.$emit("input", val);
    },
  },
};
</script>

<style lang="less" scoped></style>
