const routes = [
  //  预警
  {
    path: "/newYanshou/yujing",
    name: "yujing",
    meta: {
      title: "预警",
    },
    component: (resolve) => require(["@/views/newYanshou/yujing"], resolve),
  },
  //  临时公告
  {
    path: "/newYanshou/yongdian",
    name: "yongdian",
    meta: {
      title: "用电",
    },
    component: (resolve) => require(["@/views/newYanshou/yongdian"], resolve),
  },
  //  安防
  {
    path: "/newYanshou/anfang",
    name: "anfang",
    meta: {
      title: "安防",
    },
    component: (resolve) => require(["@/views/newYanshou/anfang"], resolve),
  },
  {
    path: "/newYanshou/anfangDetail",
    name: "anfangDetail",
    meta: {
      title: "安防",
    },
    component: (resolve) =>
      require(["@/views/newYanshou/anfangDetail"], resolve),
  },
];

export default routes;
