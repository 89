<template>
  <div class="v-comments" :style="{ 'z-index': `${zIndex}` }" v-show="show">
    <div class="overlay" @click.stop="close"></div>
    <div class="content" :class="{ noFoot: !isShowFoot }">
      <!-- <van-cell-group inset> -->
      <van-field
        ref="vanField"
        :value="value"
        :rows="rows"
        autosize
        :label="label"
        :type="type"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :disabled="disabled"
        show-word-limit
        @input="changeValue"
      />
      <!-- </van-cell-group> -->
      <footer>
        <slot name="footer">
          <div class="btns-ground">
            <div class="send btn" @click.stop="send">发送</div>
          </div>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-comments",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    // 控制是否显示
    show: {
      type: Boolean,
      default: false,
    },
    // 文本域层级
    rows: {
      type: String,
      default: "5",
    },
    // 定位层级
    zIndex: {
      type: [String, Number],
      default: 100,
    },
    // 提示文字
    placeholder: {
      type: String,
      default: "请输入",
    },
    // 输入框类型
    type: {
      type: String,
      default: "textarea",
    },
    // 左侧文字内容
    label: {
      type: String,
      default: "",
    },
    // 最大长度
    maxlength: {
      type: String,
      default: "200",
    },
    // 1/200 的脚注是否显示
    isShowFoot: {
      type: Boolean,
      default: false,
    },
    // 是否在点击发送按钮后自动关闭
    isAutoColse: {
      type: Boolean,
      default: false,
    },
    autosize: {
      type: [Boolean, Object],
      default: () => {
        return {
          maxHeight: "",
          minHeight: "",
        };
      },
    },
    // 是否禁用
    disabled: Boolean,
  },
  data() {
    return {};
  },
  watch: {
    show(newValue) {
      if (newValue) {
        return;
      }
      this.close();
    },
  },
  mounted() {},
  methods: {
    getFocus() {
      this.$refs.vanField.focus();
    },
    changeValue(val) {
      this.$emit("input", val);
    },
    send() {
      this.$emit("acceptSend", this.value);
      // 是否自动
      if (this.isAutoColse) {
        this.close();
      }
    },
    close() {
      this.$emit("update:show", false);
      // 1. 弹窗关闭后，清空输入框的值
      this.$emit("input", "");
    },
  },
};
</script>

<style lang="less" scoped>
.v-comments {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  .overlay {
    height: 100vh;
    width: 100%;
    background: #00000088;
  }
  .content {
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 32px 32px 32px 32px;
    background: #fff;
    &.noFoot {
      ::v-deep .van-field {
        .van-cell__value {
          .van-field__word-limit {
            display: none;
          }
        }
      }
    }
    ::v-deep .van-field {
      // padding: 32px 32px 16px 32px;
      padding: 24px;
      background: #f5f5f5;
      border-radius: 8px;
      overflow: hidden;
    }
    footer {
      margin-top: 16px;
      .btns-ground {
        display: flex;
        justify-content: flex-end;
        .btn {
          background: #007eff;
          color: #fff;
          font-size: 28px;
          padding: 6px 28px;
          border-radius: 8px;
          line-height: 44px;
        }
      }
    }
  }
}
</style>
