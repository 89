<template>
  <div class="v-step">
    <van-steps :active="active" active-icon="success" active-color="#38f">
      <div class="item" v-for="(item, index) in list" :key="index">
        <van-step>{{ item.value }}</van-step>
      </div>
    </van-steps>
  </div>
</template>

<script>
import Vue from "vue";
import { Step, Steps } from "vant";

Vue.use(Step);
Vue.use(Steps);
export default {
  watch: {
    value(newValue, oldValue) {},
    model(newValue) {
      this.$emit("input", newValue, this);
    },
  },
  components: {},
  props: {
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    active: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      model: this.value,
    };
  },
  methods: {
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
