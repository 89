<template>
  <div class="v-h5Mtml">
    <div class="content ql-editor" v-html="content"></div>
  </div>
</template>

<script>
export default {
  name: "v-h5Mtml",
  data() {
    return {
      //   model: this.content,
    };
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  async created() {},
  methods: {},
};
</script>
<style lang="less">
@import url("./less/quill.bubble.less");
@import url("./less/quill.core.less");
@import url("./less/quill.snow.less");
</style>
<style scoped lang="less">
.v-h5Mtml {
  .content {
    padding: 0 30px;
    font-size: 26px;
  }
  /deep/ img,
  video {
    width: 100%;
  }
}
</style>
